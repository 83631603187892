import { EBrandType, EUsageType } from 'types/subscription';
import { EExportFileType } from 'types';
import { ETrackingEvent, ETrackingOrigin, ETrackingType } from 'types/tracking';
import { PAGE_SIZE } from 'constants/general';
import { Trans, useTranslation } from 'react-i18next';
import { borderRadiusS, font, spaceM, spaceS, spaceXs, spaceXxs } from 'styles/variables';
import { formatDate } from 'utils/formatDate';
import { formatISODuration } from 'utils/formatTime';
import { formatNumber } from 'utils/number';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useCurrency } from 'hooks/useCurrency';
import { useExport } from 'hooks/useExport';
import { useModal } from 'contexts/Modal';
import { useSearchParams } from 'react-router-dom';
import AppIcon from 'assets/icons/phone.svg?react';
import CalendarIcon from 'assets/icons/calendar.svg?react';
import CarIcon from 'assets/icons/car_side.svg?react';
import CardIcon from 'assets/icons/credit_card.svg?react';
import ChargeIcon from 'assets/icons/power_circle.svg?react';
import ChargerIcon from 'assets/icons/charger.svg?react';
import ChargingHistoryFilter from './ChargingHistoryFilter';
import ChargingHistoryIconInfoPopup from 'components/info/ChargingHistoryIconInfoPopup';
import CircleIcon from 'assets/icons/pie_circle.svg?react';
import ClockIcon from 'assets/icons/clock.svg?react';
import ExportButton from 'components/clickables/ExportButton';
import FilterButton from 'components/clickables/FilterButton';
import GridList, { GridListHeader, GridListItem } from 'components/lists/GridList';
import ListFooter from 'components/lists/ListFooter';
import LocationIcon from 'assets/icons/location_pin.svg?react';
import PopUp from 'components/info/PopUp';
import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import RFIDIcon from 'assets/icons/rfid.svg?react';
import ReactGA from 'react-ga4';
import ReferenceIcon from 'assets/icons/speech_bubble.svg?react';
import SearchInput from 'components/forms/SearchInput';
import SelectedFilterBar from 'components/widgets/SelectedFilterBar';
import SubscriptionIcon from 'assets/icons/subscription_type.svg?react';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import TextEllipsisComponent from 'components/info/TextEllipsisComponent';
import TooltipContentComponent from 'components/info/TooltipContent';
import UserIcon from 'assets/icons/user.svg?react';
import styled from 'styled-components';
import useSubscriptionChargeDetails from 'hooks/useSubscriptionChargeDetails';
import useSubscriptionChargeDetailsExport from 'hooks/useSubscriptionChargeDetailsExport';

const gridColumn = 'repeat(4, 1fr)';

const Container = styled.section`
    margin: ${spaceM} 0;
`;

const UtilityBar = styled.div`
    display: flex;
    gap: ${spaceS};
    margin-bottom: ${spaceS};
`;

const UtilityBarWrapper = styled.div`
    margin-bottom: ${spaceS};
`;

const ExportButtonContainer = styled.div`
    display: flex;
    gap: ${spaceXs};
    .tippyButton {
        margin-top: -${spaceXxs};
    }
`;

const StyledFilterButton = styled(FilterButton)`
    justify-self: flex-start;
`;

export const StyledGridListItem = styled(GridListItem)`
    padding-top: ${spaceS};
    padding-bottom: ${spaceS};
    height: auto;
    p {
        line-height: ${font.lineHeight.m};
    }
`;

export const TextContainer = styled.div`
    display: grid;
    grid-template-columns: 1rem 1fr;
    gap: ${spaceXs};
    align-items: center;
    svg {
        max-height: 1rem;
        max-width: 1rem;
        justify-self: center;
    }
`;

export const StyledGridListHeader = styled(GridListHeader)`
    white-space: nowrap;
    font-weight: 700;
    border-radius: ${borderRadiusS};
    display: flex !important;
`;

type FilterData = {
    dateFrom: string;
    dateTo: string;
};

export interface ChargingHistoryProps {
    organisationsDropDown?: JSX.Element;
}

function ChargingHistory({ organisationsDropDown }: ChargingHistoryProps): JSX.Element {
    const { t } = useTranslation();
    const { open } = useModal();
    const organisationIds = useContext(SubscriptionStatisticsContext);

    const { locale } = useCountrySpecificContent();
    const { toCurrency } = useCurrency();

    const [searchParams, setSearchParams] = useSearchParams();

    const searchPageNumber = searchParams.get('pageNumber');

    const [pageNumber, setPageNumber] = useState<number>(searchPageNumber ? Number(searchPageNumber) : 1);
    const searchTerm = searchParams.get('searchTerm');

    if (searchParams.get('tab') !== 'chargingHistory') {
        searchParams.set('tab', 'chargingHistory');
        searchParams.delete('pageNumber');
        searchParams.delete('searchTerm');
    }

    useEffect(() => {
        if (pageNumber === 1) {
            searchParams.set('pageNumber', '1');
        } else {
            searchParams.set('pageNumber', String(pageNumber));
        }

        setSearchParams(searchParams);
    }, [pageNumber, searchParams, setSearchParams]);

    const [filterData, setFilterData] = useState<FilterData>({
        dateFrom: '',
        dateTo: '',
    });

    const [fileType, setFileType] = useState<EExportFileType>(EExportFileType.EXCEL);

    const chargingQueryData = {
        businessIds: organisationIds,
        pageNumber: pageNumber - 1,
        pageSize: PAGE_SIZE,
        searchTerm: searchTerm ?? '',
        dateFrom: filterData.dateFrom,
        dateTo: filterData.dateTo,
        fileType,
    };

    const getExport = useSubscriptionChargeDetailsExport(chargingQueryData);

    const {
        chargeDetailsList,
        error: errorSubscriptionChargeDetails,
        isLoading: isLoadingSubscriptionChargeDetails,
    } = useSubscriptionChargeDetails(chargingQueryData);

    const historyExists = chargeDetailsList?.billingTransactions && chargeDetailsList?.billingTransactions.length > 0;

    const { startExport, somethingToExport } = useExport(historyExists);

    const handleOnFilter = (filters: object): void => {
        setFilterData(filters as FilterData);
        setPageNumber(1);
    };

    const showFilter = (): void => {
        open(<ChargingHistoryFilter onFilter={handleOnFilter} filterData={filterData} />);
    };

    const handlePagination = (page: number): void => {
        setPageNumber(page);
    };

    const handleOnClearFilter = (value: string): void => {
        if (value === 'dateRange') {
            setFilterData({
                ...filterData,
                dateFrom: '',
                dateTo: '',
            });
        } else {
            setFilterData({
                ...filterData,
            });
        }
    };

    const selectedFilters = useMemo(() => {
        const filterArray = [];
        if (filterData.dateFrom && filterData.dateTo) {
            filterArray.push({
                text: `${filterData.dateFrom} - ${filterData.dateTo}`,
                value: 'dateRange',
            });
        }
        return filterArray;
    }, [filterData]);

    const hasBillingTransactions = chargeDetailsList && chargeDetailsList?.billingTransactions?.length > 0;

    return (
        <Container>
            <UtilityBarWrapper>
                <UtilityBar>
                    {organisationsDropDown}
                    <SearchInput placeholder={t('subscription.chargingHistory.searchPlaceHolder')} />
                    <StyledFilterButton dataTestId="filterButton" showFilter={showFilter} />
                    <ExportButtonContainer>
                        <ExportButton
                            setFileType={setFileType}
                            isLoading={getExport.isPending}
                            getExport={() => {
                                ReactGA.event(ETrackingEvent.EXPORT_FILE, {
                                    origin: ETrackingOrigin.CHARGING_HISTORY,
                                });
                                startExport(() => getExport.mutateAsync(), historyExists);
                            }}
                            showMessage={!somethingToExport}
                        />
                        <PopUp
                            dataTestId="exportInfoButton"
                            gaTracking={{
                                type: ETrackingType.EXPORT_INFO,
                                origin: ETrackingOrigin.CHARGING_HISTORY,
                            }}
                            content={
                                <TooltipContentComponent
                                    headline={t('subscription.chargingHistory.exportInfoTitle')}
                                    content={<Trans i18nKey="subscription.chargingHistory.exportInfodescription" />}
                                    icon={<QuestionMarkTooltipIcon data-testid="chargingHistoryToolTipIcon" />}
                                />
                            }
                        />
                    </ExportButtonContainer>
                </UtilityBar>
                {selectedFilters.length > 0 && (
                    <SelectedFilterBar
                        selectedFilters={selectedFilters}
                        onClearFilter={handleOnClearFilter}
                        onItemClick={showFilter}
                        className="selected-filters"
                    />
                )}
            </UtilityBarWrapper>
            <>
                <GridList
                    gridColumns={gridColumn}
                    dataTestId="cdrList"
                    showEmptyListMessage={
                        !isLoadingSubscriptionChargeDetails &&
                        !hasBillingTransactions &&
                        !errorSubscriptionChargeDetails
                    }
                    isLoading={isLoadingSubscriptionChargeDetails}
                    hasError={!!errorSubscriptionChargeDetails}
                >
                    <StyledGridListHeader>
                        {t('subscription.chargingHistory.sessions')} <ChargingHistoryIconInfoPopup />
                    </StyledGridListHeader>
                    {!isLoadingSubscriptionChargeDetails &&
                        hasBillingTransactions &&
                        chargeDetailsList?.billingTransactions.map((item) => {
                            return (
                                <StyledGridListItem key={item.id}>
                                    <TextContainer>
                                        <UserIcon />
                                        <TextEllipsisComponent tooltipText={item.driverName}>
                                            {item.driverName}
                                        </TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <ReferenceIcon />
                                        <TextEllipsisComponent tooltipText={item.reference}>
                                            {item.reference}
                                        </TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <CarIcon />
                                        <TextEllipsisComponent>{item.licencePlate}</TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <ClockIcon />
                                        <TextEllipsisComponent>
                                            {formatISODuration(item.chargeTime)}
                                        </TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <LocationIcon />
                                        <TextEllipsisComponent tooltipText={item.siteName}>
                                            {item.siteName}
                                        </TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <ChargeIcon />
                                        <TextEllipsisComponent>{item.currentType}</TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <CardIcon />
                                        <TextEllipsisComponent>
                                            {toCurrency({
                                                amount: item.totalCost,
                                                currency: item.currency,
                                            })}
                                        </TextEllipsisComponent>
                                    </TextContainer>

                                    <TextContainer>
                                        {item.brandType === EBrandType.PHYSICAL_CARD ? <RFIDIcon /> : <AppIcon />}
                                        <TextEllipsisComponent>{item.cardVisibleNumber}</TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <CalendarIcon />
                                        <TextEllipsisComponent>
                                            {item.startTime && formatDate(new Date(item.startTime), locale, false)}
                                        </TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <ChargerIcon />
                                        <TextEllipsisComponent>
                                            {item.location === EUsageType.ROAMING && item.roamingPartnerName
                                                ? `${t(`location.${item.location}`)} - ${item.roamingPartnerName}`
                                                : t(`location.${item.location}`)}
                                        </TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <SubscriptionIcon />
                                        <TextEllipsisComponent>{item.subscriptionType}</TextEllipsisComponent>
                                    </TextContainer>
                                    <TextContainer>
                                        <CircleIcon />
                                        <TextEllipsisComponent>
                                            {`${formatNumber(item.consumedEnergy)} kWh`}
                                        </TextEllipsisComponent>
                                    </TextContainer>
                                </StyledGridListItem>
                            );
                        })}
                </GridList>
                {chargeDetailsList &&
                    chargeDetailsList.billingTransactions.length >= 1 &&
                    !isLoadingSubscriptionChargeDetails && (
                        <ListFooter
                            data={chargeDetailsList}
                            handlePagination={handlePagination}
                            pageNumber={pageNumber}
                        />
                    )}
            </>
        </Container>
    );
}

export default ChargingHistory;
